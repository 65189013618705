import { toArray, areNodesPresent, request } from './utils.js';

const NEWSLETTER_SELECTOR = '#newsletter';
const INPUT_SELECTOR      = '#input';
const SUBMIT_SELECTOR     = '.js-submit-newsletter';
const SENSITIVE_CLASS     = 'is-sensitive';
const OPEN_CLASS          = 'is-open';
const SUCCESS_CLASS       = 'is-success';

class Newsletter {
  constructor() {
    const nodes = this.getNodes();
    if (areNodesPresent(nodes)) {
      this.DOM = nodes;
      this.bindEmailKeyupListener();
      this.bindSubmitButtons();
      this.bindClickEvents();
    }
  }

  getNodes() {
    return {
      newsletter: document.querySelector(NEWSLETTER_SELECTOR),
      input: document.querySelector(INPUT_SELECTOR),
      buttons: document.querySelectorAll(SUBMIT_SELECTOR)
    };
  }

  bindEmailKeyupListener() {
    const input = document.querySelector(INPUT_SELECTOR);
    input.addEventListener('input', () => {
      this.DOM.newsletter.classList.remove(SUCCESS_CLASS);
      this.validateEmail.call(this);
    });
  }

  bindSubmitButtons() {
    toArray(this.DOM.buttons).forEach(button => {
      button.addEventListener('click', (ev) => {
        ev.stopPropagation();
        this.sensitizeInput();
        if (this.validateEmail()) {
          this.submit();
        }
      });
    });
  }

  bindClickEvents() {
    this.DOM.newsletter.addEventListener('click', ev => {
      ev.stopPropagation();
    });

    document.body.addEventListener('click', () => {
      this.DOM.newsletter.classList.remove(OPEN_CLASS);
    });
  }

  sensitizeInput() {
    this.DOM.input.classList.add(SENSITIVE_CLASS);
  }

  validateEmail() {
    const isValid = this.DOM.input.checkValidity();
    if (isValid) {
      this.DOM.newsletter.classList.add('is-valid');
      this.DOM.newsletter.classList.remove('is-invalid');
    } else {
      this.DOM.newsletter.classList.add('is-invalid');
      this.DOM.newsletter.classList.remove('is-valid');
    }
    return isValid;
  }

  submit() {
    request({
      action: 'sub_mailchimp',
      email: this.DOM.input.value
    }, (error) => {
      if (error) {
        this.onSubmitError();
      } else {
        this.onSubmitSuccess();
      }
    });
  }

  onSubmitSuccess() {
    this.DOM.newsletter.classList.add(SUCCESS_CLASS);
    this.DOM.newsletter.classList.remove(OPEN_CLASS);
  }

  onSubmitError() {
    this.DOM.newsletter.classList.add('is-invalid');
  }
}

export default Newsletter;
