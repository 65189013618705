import { toArray } from './utils.js';

const TRIGGER_SELECTOR = '.js-click-redirect';

class ClassToggler {
  constructor() {
    const triggers = document.querySelectorAll(TRIGGER_SELECTOR);

    toArray(triggers).forEach(trigger => {
      trigger.addEventListener('click', () => {
        const target = trigger.getAttribute('data-target');
        const targetElement = document.querySelector(target);
        if (document.documentElement.classList.contains('no-touchevents')) {
          targetElement.click();
        }
      });
    });
  }
}

export default ClassToggler;
