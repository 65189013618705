import { toArray } from './utils';

class ObjectFitFallback {
  constructor() {
    const images = document.getElementsByClassName('js-object-fit');
    toArray(images).forEach(image => {
      if (image.style.objectFit === undefined) {
        this.applyFix(image);
      }
    });
  }

  applyFix(image) {
    image.style.opacity = 0;
    image.parentNode.style.backgroundImage = 'url('+image.src+')';
    image.parentNode.classList.add('u-bg-cover');

  }
}

export default ObjectFitFallback;
