import { toArray, areNodesPresent } from './utils';

const HEADER_SELECTOR       = '#header';
const HIDDEN_NAV_SELECTOR   = '#hidden-nav-desktop';
const BODY_WRAPPER_SELECTOR = '#body-wrapper';

class ScrollManager {

  constructor() {
    const dom = this.getDOM();
    if (areNodesPresent(dom)) {
      this.DOM = dom;
      this.duration = 600;
      this.setupEvents();
    }
  }

  getDOM() {
    return {
      triggers: document.querySelectorAll('.js-scroll')
    };
  }

  setupEvents() {
    toArray(this.DOM.triggers).forEach(trigger => {
      trigger.addEventListener('click', this.scroll.bind(this));
    });
  }

  getCurrentScroll() {
    let yScroll;

    if (window.pageYOffset) {
      yScroll = window.pageYOffset;
    } else if (document.documentElement && document.documentElement.scrollTop) {
      yScroll = document.documentElement.scrollTop;
    } else if (document.body) {
      yScroll = document.body.scrollTop;
    }
    return yScroll;
  }

  getPageScroll() {
    const windowHeight = document.documentElement.clientHeight;
    const scrollableHeight = document.documentElement.scrollHeight;
    const maxScrollTop = Math.max(0, scrollableHeight - windowHeight);
    return Math.min(windowHeight, maxScrollTop);
  }

  getHeaderHeight() {
    const header = document.querySelector(HEADER_SELECTOR);
    return header ? header.offsetHeight : 0;
  }

  getHiddenHeaderHeight() {
    const header = document.querySelector(HIDDEN_NAV_SELECTOR);
    return header ? header.offsetHeight : 0;
  }

  getBodyWrapper() {
    return document.querySelector(BODY_WRAPPER_SELECTOR);
  }

  transform(value, factor) {
    factor = factor ? factor : 1;
    return 'translate(0, ' + (value * factor) + 'px)';
  }

  scroll() {
    const bodyWrapper = this.getBodyWrapper();
    const targetOffset = this.getPageScroll();
    const currentPosition = this.getCurrentScroll();
    const headerHeight = this.getHiddenHeaderHeight() || this.getHeaderHeight();
    const value = targetOffset - currentPosition - headerHeight;

    document.body.classList.add('in-transition');
    bodyWrapper.style.transform = this.transform(value, -1);

    window.setTimeout(() => {
      document.body.classList.remove('in-transition');
      bodyWrapper.style.cssText = '';
      window.scrollTo(0, targetOffset - headerHeight);
    }, this.duration);
  }
}

export default ScrollManager;
