import { toArray } from './utils';

const GALLERY_DRAWER_SELECTOR = '#gallery-drawer';
const TRIGGER_SELECTOR        = '.js-expand-if-mobile';
const EXPANDED_CLASSNAME      = 'is-expanded';
const SMALL_VIEWPORT_MAX      = 768;

class GalleryState {
  constructor() {
    const triggers = document.querySelectorAll(TRIGGER_SELECTOR);
    toArray(triggers).forEach(trigger => {
      trigger.addEventListener('click', () => {
        const isViewportSmall = window.innerWidth < SMALL_VIEWPORT_MAX ? true : false;
        if (isViewportSmall) {
          const drawer = document.querySelector(GALLERY_DRAWER_SELECTOR);
          if (drawer) {
            drawer.classList.add(EXPANDED_CLASSNAME);
          }
        }
      });
    });
  }
}

export default GalleryState;
