const HOTEL_LOCATION = window.hotelLocation;

const MARKET_LOGO_WIDTH = 72;
const MARKET_LOGO_HEIGHT = 116;

class MapHome {
  constructor() {
    this.homeMap = document.querySelector('.js-home-map');

    if(this.homeMap) {
      window.onMapsLoad(() => this.setupMap());
    }
  }

  setupMap() {
    const google = window.google;
    const map = this.map = new google.maps.Map(this.homeMap, {
      center: HOTEL_LOCATION,
      scrollwheel: true,
      zoom: 16,
      styles: window.mapsStyling,
      scrollwheel: false,
      fullscreenControl: false,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false
    });
    this.twoStepLoad();
    const marker = new google.maps.Marker({
      map,
      position: HOTEL_LOCATION,
      icon: {
        url: `${window.templateUrl}/dist/assets/markers/contact.svg`,
        scaledSize: new google.maps.Size(MARKET_LOGO_WIDTH, MARKET_LOGO_HEIGHT)
      },
      optimized: false
    });
    marker.addListener('click', () => window.open(window.hotelUrl));
  }

  twoStepLoad() {
    const google = window.google;
    const map = this.map;
    let firstBounds = null;
    const firstBoundsChanged = () => {
      firstBounds = map.getCenter().toJSON();
      google.maps.event.addListenerOnce(map, 'bounds_changed', secondBoundsChanged);
      this.homeMap.parentNode.classList.remove('is-small');
      google.maps.event.trigger(map, 'resize');
    }
    const secondBoundsChanged = () => {
      const secondBounds = map.getCenter().toJSON();
      this.calculatedCenter = {
        lat: firstBounds.lat + firstBounds.lat - secondBounds.lat,
        lng: firstBounds.lng + firstBounds.lng - secondBounds.lng
      }
      map.setCenter(this.calculatedCenter);
    }
    google.maps.event.addListenerOnce(map, 'bounds_changed', firstBoundsChanged);
  }
}

export default MapHome;
