import { areNodesPresent } from './utils.js';

const MAIN_SELECTOR           = '.js-slicegrid-carousel';
const PREV_SELECTOR           = '.js-slicegrid-carousel-prev';
const NEXT_SELECTOR           = '.js-slicegrid-carousel-next';
const BTN_SELECTOR            = '.c-slicegrid__link';
const OUTERCONTAINER_SELECTOR = '.c-slicegrid__outercontainer';
const INNERCONTAINER_SELECTOR = '.c-slicegrid__innercontainer';
const ELEMENTS_SELECTOR       = '.c-slicegrid__slice';
const VIEW_BTN_SELECTOR       = '.c-slicegrid__view-button';

const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;

const REQUIRED_DRAG = 20;
const REQUIRED_DRAG_PERCENTAGE = 10;
const PERCENTS = 100;

class SlicegridCarousel {
  constructor() {
    const carousel = document.querySelector(MAIN_SELECTOR);
    if (carousel) {
      const nodes = this.getNodes(carousel);
      if (areNodesPresent(nodes)) {
        this.DOM = nodes;
        this.setup();
        this.bindNavEvents();
        this.bindTouchEvents();
        this.bindKeyboardEvents();
      }
    }
  }

  setup() {
    this.total = this.DOM.elements.length;
    this.slideTo(0);
  }

  getNodes(carousel) {
    return {
      carousel,
      outercontainer: carousel.querySelector(OUTERCONTAINER_SELECTOR),
      innercontainer: carousel.querySelector(INNERCONTAINER_SELECTOR),
      elements:  carousel.querySelectorAll(ELEMENTS_SELECTOR),
      btn:       carousel.querySelector(BTN_SELECTOR),
      prev:      document.querySelector(PREV_SELECTOR),
      next:      document.querySelector(NEXT_SELECTOR),
    };
  }

  bindNavEvents() {
    this.DOM.prev.addEventListener('click', () => {
      const prev = Math.max(0, this.current - 1);
      this.slideTo(prev);
    });
    this.DOM.next.addEventListener('click', () => {
      const next = Math.min(this.total-1, this.current + 1);
      this.slideTo(next);
    });
  }

  slideTo(n) {
    if (n < 0 || n > this.total-1) {
      return;
    }
    this.translateTo(-n*PERCENTS);
    this.current = n;
    this.setButtonUrl(n);
    this.updateNavState();
  }

  updateNavState() {
    if (this.current === 0) {
      this.DOM.prev.classList.add('disabled');
    } else {
      this.DOM.prev.classList.remove('disabled');
    }

    if (this.current === this.total-1) {
      this.DOM.next.classList.add('disabled');
    } else {
      this.DOM.next.classList.remove('disabled');
    }
  }

  translateTo(value) {
    this.DOM.innercontainer.style.transform = 'translateX('+value+'%)';
  }

  setButtonUrl(n) {
    const href = this.DOM.elements[n].querySelector(VIEW_BTN_SELECTOR).getAttribute('href');
    this.DOM.btn.setAttribute('href', href);
  }

  bindKeyboardEvents() {
    document.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KEYCODE_LEFT) {
        this.slideTo(this.current - 1);
      }
      if (ev.keyCode === KEYCODE_RIGHT) {
        this.slideTo(this.current + 1);
      }
    });
  }

  bindTouchEvents() {
    const touchMove = this.touchMove.bind(this);
    this.DOM.outercontainer.addEventListener('touchstart', (ev) => {
      this.direction = undefined;
      this.currentDragPercentage = 0;
      this.touchPositionX = ev.changedTouches[0].screenX;
      this.touchPositionY = ev.changedTouches[0].screenY;

      this.DOM.carousel.classList.add('is-dragging');
      this.DOM.outercontainer.addEventListener('touchmove', touchMove);
    });
    this.DOM.outercontainer.addEventListener('touchend', () => {
      this.direction = undefined;
      this.DOM.carousel.classList.remove('is-dragging');
      this.DOM.outercontainer.removeEventListener('touchmove', touchMove);
      this.snap();
    });
  }

  touchMove(ev) {
    const carouselWidth = this.DOM.carousel.offsetWidth;
    const deltaX = ev.changedTouches[0].screenX - this.touchPositionX;
    const deltaY = ev.changedTouches[0].screenY - this.touchPositionY;
    const absDeltaX = Math.abs(deltaX);
    const absDeltaY = Math.abs(deltaY);

    if (!this.direction && (absDeltaX > REQUIRED_DRAG || absDeltaY > REQUIRED_DRAG)) {
      this.direction = absDeltaX > absDeltaY ? 'h' : 'v';
    }

    if (this.direction === 'h') {
      ev.preventDefault();
      const percentage = deltaX / carouselWidth * PERCENTS;
      this.drag(percentage);
    }
  }

  drag(percentage) {
    const newPosition = (this.current * PERCENTS * -1) + percentage;
    this.currentDragPercentage = percentage;
    this.translateTo(newPosition);
  }

  snap() {
    const total = this.total;
    let snapIndex = this.current;
    if (this.currentDragPercentage < REQUIRED_DRAG_PERCENTAGE * -1) {
      snapIndex++;
    } else if (this.currentDragPercentage > REQUIRED_DRAG_PERCENTAGE) {
      snapIndex--;
    }
    this.slideTo(Math.min(Math.max(0, snapIndex), total-1));
  }
}

export default SlicegridCarousel;
