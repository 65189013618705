import { toArray } from './utils';

class FormOverlay {
  constructor() {
    this.form = document.querySelector('.js-form');
    this.formContent = document.querySelector('.js-form-content');
    this.formTogglers = toArray(document.querySelectorAll('.js-form-toggle'));
    this.body = document.body;
    // 1080px has ben found exerimentally with inspector
    this.formResize = window.matchMedia('(min-width: 1080px)');
    this.formByID = {};

    this.isOpen = false;
    this.bodyScroll = null;
    this.activeForm = null;

    this.setupEvents();
  }

  setupEvents() {
    this.formTogglers.forEach((el) => {
      let form = el.children[0];
      let id = null;
      if(form && form.classList.contains('js-form-body')) {
        const el = form.querySelector('[id^="wufoo-"]');
        if(el) {
          id = el.id.split('-')[1];
          if(id) {
            this.formByID[id] = (this.formByID[id] || form);
            this.formResize.addListener(() => window[id] && window[id].display());
          }
        }
        this.formContent.appendChild(form);
      } else {
        form = null;
      }
      el.addEventListener('click', () => {
        this.toggle((id && this.formByID[id]) || form);
        if(form && id && window[id]) {
          window[id].display();
        }
      });
    });
  }

  toggle(content) {
    if(this.activeForm) {
      this.activeForm.style.display = 'none';
      this.activeForm = null;
    }
    if(!this.isOpen && content) {
      this.activeForm = content;
      this.activeForm.style.display = 'block';
    }
    this.form.classList.toggle('is-open');
    this.lockOrUnlockBodyScroll();
    this.isOpen = !this.isOpen;
  }

  lockOrUnlockBodyScroll() {
    if(this.bodyScroll === null) {
      this.bodyScroll = window.pageYOffset;
      this.body.classList.toggle('u-fixed');
    } else {
      this.body.classList.toggle('u-fixed');
      window.scrollTo(0, this.bodyScroll);
      this.bodyScroll = null;
    }
  }
}

export default FormOverlay;
