import { areNodesPresent, toArray } from './utils.js';

const MAIN_SELECTOR           = '.js-carousel';
const OUTERCONTAINER_SELECTOR = '.js-carousel-outercontainer';
const INNERCONTAINER_SELECTOR = '.js-carousel-innercontainer';
const ELEMENTS_SELECTOR       = '.js-carousel-element';
const CURRENT_SELECTOR        = '.js-carousel-current';
const NAV_SELECTOR            = '.js-carousel-nav';
const PREV_SELECTOR           = '.js-carousel-prev';
const NEXT_SELECTOR           = '.js-carousel-next';
// const DOT_CONTAINER_SELECTOR  = '.js-carousel-dot-container';
// const DOT_CLASSNAME           = 'js-carousel-dot';
const ACT_CLASSNAME           = 'is-active';
const HIDDEN_CLASSNAME        = 'is-hidden';

const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;
const REQUIRED_DRAG = 20;
const REQUIRED_DRAG_PERCENTAGE = 10;
const PERCENTS = 100;
const RESIZE_DEBOUNCE_TIME = 200;

class Carousel {
  constructor() {
    const carousels = document.querySelectorAll(MAIN_SELECTOR);

    toArray(carousels).forEach(carousel => {
      const nodes = this.getNodes(carousel);
      if (areNodesPresent(nodes)) {
        this.init({
          DOM: nodes,
          bindKeyboardEvents: this.bindKeyboardEvents,
          bindTouchEvents: this.bindTouchEvents,
          bindNavEvents: this.bindNavEvents,
          bindResizeWindow: this.bindResizeWindow,
          // bindDotEvents: this.bindDotEvents,
          // getDotClasses: this.getDotClasses,
          // setActiveDot: this.setActiveDot,
          // setupDots: this.setupDots,
          setActiveElement: this.setActiveElement,
          setInnerContainerHeight: this.setInnerContainerHeight,
          createDots: this.createDots,
          hideNav: this.hideNav,
          setCurrentIndicator: this.setCurrentIndicator,
          updateNavState: this.updateNavState,
          translateTo: this.translateTo,
          touchMove: this.touchMove,
          slideTo: this.slideTo,
          drag: this.drag,
          snap: this.snap
        });
      }
    });
  }

  getNodes(carousel) {
    return {
      carousel,
      outercontainers: carousel.querySelectorAll(OUTERCONTAINER_SELECTOR),
      innercontainers: carousel.querySelectorAll(INNERCONTAINER_SELECTOR),
      elements:        carousel.querySelectorAll(ELEMENTS_SELECTOR),
      // dotContainer:    carousel.querySelector(DOT_CONTAINER_SELECTOR)
    };
  }

  init(instance) {
    const total = instance.DOM.elements.length;
    if (total) {
      instance.total = total;
      // instance.setupDots();
      instance.slideTo(0);
      instance.bindTouchEvents();
      instance.bindNavEvents();
      instance.bindKeyboardEvents();
      instance.bindResizeWindow();
    }

    if (total < 2) {
      instance.hideNav();
    }
  }

  hideNav() {
    const navs = this.DOM.carousel.querySelectorAll(NAV_SELECTOR);
    toArray(navs).forEach(nav => {
      nav.classList.add(HIDDEN_CLASSNAME);
    });
  }

  slideTo(n) {
    if (n < 0 || n > this.total-1) {
      return;
    }
    this.translateTo(-n*PERCENTS);
    // this.setActiveDot(n);
    this.setActiveElement(n);
    this.setInnerContainerHeight(n);
    this.current = n;
    this.currentDragPercentage = 0;
    this.setCurrentIndicator(n+1);
    this.updateNavState();
  }

  updateNavState() {
    const prevs = toArray(this.DOM.carousel.querySelectorAll(PREV_SELECTOR));
    const nexts = toArray(this.DOM.carousel.querySelectorAll(NEXT_SELECTOR));


    if (this.current === 0) {
      prevs.forEach(prev => {
        prev.classList.add('disabled');
      });
    } else {
      prevs.forEach(prev => {
        prev.classList.remove('disabled');
      });
    }

    if (this.current === this.total-1) {
      nexts.forEach(next => {
        next.classList.add('disabled');
      });
    } else {
      nexts.forEach(next => {
        next.classList.remove('disabled');
      });
    }
  }

  setCurrentIndicator(n) {
    toArray(this.DOM.carousel.querySelectorAll(CURRENT_SELECTOR)).forEach(current => {
      current.innerHTML = n;
    });
  }

  translateTo(value) {
    toArray(this.DOM.innercontainers).forEach(innercontainer => {
      innercontainer.style.transform = 'translateX('+value+'%)';
    });
  }

  setActiveElement(n) {
    toArray(this.DOM.innercontainers).forEach(innercontainer => {
      toArray(innercontainer.children).forEach((el, i) => {
        const action = (i === n) ? 'add' : 'remove';
        el.classList[action](ACT_CLASSNAME);
      });
    });
  }

  setInnerContainerHeight() {
    toArray(this.DOM.innercontainers).forEach(innercontainer => {
      toArray(innercontainer.children).forEach(el => {
        if (el.classList.contains(ACT_CLASSNAME)) {
          innercontainer.style.height = el.offsetHeight + 'px';
        }
      });
    });
  }

  // setupDots() {
  //   const classes = this.getDotClasses().join(' ');
  //   this.DOM.dotContainer.innerHTML = '';
  //   this.createDots(classes);
  //   this.DOM.dotContainer.firstChild.classList.add(ACT_CLASSNAME);
  //   this.bindDotEvents();
  // }

  // getDotClasses() {
  //   let classes = [];
  //   const dots = this.DOM.dotContainer.children;
  //   if (dots.length) {
  //     classes.push(dots[0].classList);
  //   }
  //   classes.push(DOT_CLASSNAME);
  //   return classes;
  // }

  // createDots(classes) {
  //   this.DOM.dots = [];
  //   toArray(this.DOM.elements).forEach((el, i) => {
  //     const dot = document.createElement('li');
  //     dot.className = classes;
  //     dot.setAttribute('data-index', i);
  //     this.DOM.dotContainer.appendChild(dot);
  //     this.DOM.dots.push(dot);
  //   });
  // }

  // setActiveDot(n) {
  //   this.DOM.dots.forEach((dot, i) => {
  //     const action = (i === n) ? 'add' : 'remove';
  //     dot.classList[action](ACT_CLASSNAME);
  //   });
  // }

  // bindDotEvents() {
  //   this.DOM.dots.forEach(dot => {
  //     dot.addEventListener('click', () => {
  //       const index = Number(dot.getAttribute('data-index'));
  //       this.slideTo(index);
  //     });
  //   });
  // }

  bindNavEvents() {
    toArray(this.DOM.carousel.querySelectorAll(PREV_SELECTOR)).forEach(prev => {
      prev.addEventListener('click', () => {
        this.slideTo(this.current - 1);
      });
    });
    toArray(this.DOM.carousel.querySelectorAll(NEXT_SELECTOR)).forEach(next => {
      next.addEventListener('click', () => {
        this.slideTo(this.current + 1);
      });
    });
  }

  bindKeyboardEvents() {
    document.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KEYCODE_LEFT) {
        this.slideTo(this.current - 1);
      }
      if (ev.keyCode === KEYCODE_RIGHT) {
        this.slideTo(this.current + 1);
      }
    });
  }

  bindTouchEvents() {
    const touchMove = this.touchMove.bind(this);
    toArray(this.DOM.outercontainers).forEach(outercontainer => {
      outercontainer.addEventListener('touchstart', (ev) => {
        this.direction = undefined;
        this.touchPositionX = ev.changedTouches[0].screenX;
        this.touchPositionY = ev.changedTouches[0].screenY;
        this.DOM.carousel.classList.add('is-dragging');
        outercontainer.addEventListener('touchmove', touchMove);
      });
      outercontainer.addEventListener('touchend', () => {
        this.direction = undefined;
        this.DOM.carousel.classList.remove('is-dragging');
        outercontainer.removeEventListener('touchmove', touchMove);
        this.snap();
      });
    });
  }

  bindResizeWindow() {
    var tmoHandler;
    window.addEventListener('resize', () => {
      clearTimeout(tmoHandler);
      tmoHandler = setTimeout(() => {
        this.setInnerContainerHeight();
      }, RESIZE_DEBOUNCE_TIME);
    });
  }

  touchMove(ev) {
    const carouselWidth = this.DOM.carousel.offsetWidth;
    const deltaX = ev.changedTouches[0].screenX - this.touchPositionX;
    const deltaY = ev.changedTouches[0].screenY - this.touchPositionY;
    const absDeltaX = Math.abs(deltaX);
    const absDeltaY = Math.abs(deltaY);

    if (!this.direction && (absDeltaX > REQUIRED_DRAG || absDeltaY > REQUIRED_DRAG)) {
      this.direction = absDeltaX > absDeltaY ? 'h' : 'v';
    }

    if (this.direction === 'h') {
      ev.preventDefault();
      const percentage = deltaX / carouselWidth * PERCENTS;
      this.drag(percentage);
    }
  }

  drag(percentage) {
    const newPosition = (this.current * PERCENTS * -1) + percentage;
    this.currentDragPercentage = percentage;
    this.translateTo(newPosition);
  }

  snap() {
    const total = this.total;
    let snapIndex = this.current;
    if (this.currentDragPercentage < REQUIRED_DRAG_PERCENTAGE * -1) {
      snapIndex++;
    } else if (this.currentDragPercentage > REQUIRED_DRAG_PERCENTAGE) {
      snapIndex--;
    }
    this.slideTo(Math.min(Math.max(0, snapIndex), total-1));
  }
}

export default Carousel;
