import { toArray } from './utils';

const START = 70;
const END = -70;
const DIFF = START - END;

class Parallax {
  constructor() {
    this.elements = toArray(document.querySelectorAll('.js-parallax'));
    this.observer = null;
    this.watchedElements = new window.Set();
    this.bindedFrame = this.frame.bind(this);
    this.animationRequest = 0;

    this.createObserver();
    this.setupObserver();
  }

  createObserver() {
    const observerOptions = {};
    this.observer =
      new window.IntersectionObserver(this.observerCallback.bind(this), observerOptions);
  }

  setupObserver() {
    this.elements.forEach((el) => this.observer.observe(el));

    // Cleanup elements so they can be GCed when they are removed from DOM
    this.elements = null;
  }

  observerCallback(entries) {
    const elements = this.watchedElements;
    entries.forEach((entry) => {
      const el = entry.target;
      if(entry.intersectionRatio == 0 && !elements.has(el)) {
        return;
      }
      if(elements.has(el)) {
        elements.delete(el);
        //console.log('out', el, document.body.contains(el), entry);
      } else {
        elements.add(el);
        //console.log('in', el, document.body.contains(el), entry);
      }
      el.classList.toggle('animating');
    })

    if(!this.animationRequest && elements.size > 0) {
      this.requestAnimations = true;
      this.animationRequest = window.requestAnimationFrame(this.bindedFrame);
    } else if(this.animationRequest && elements.size == 0) {
      window.cancelAnimationFrame(this.animationRequest);
      this.animationRequest = 0;
    }
  }

  frame() {
    const windowHeight = window.innerHeight;

    this.watchedElements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const top = rect.top;
      const pos = top/windowHeight;
      const transform = Math.max(Math.min(pos*DIFF, DIFF), 0) - START;
      el.style.transform = `translate3d(0, ${transform}px, 0)`;
    })

    this.animationRequest = window.requestAnimationFrame(this.bindedFrame);
  }

  add(els) {
    els.forEach((el) => {
      this.observer.observe(el);
    })
  }
}

export default Parallax
