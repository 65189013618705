export function areNodesPresent(nodelist) {
  if (nodelist) {
    const requiredNodes = Object.keys(nodelist);
    const availableNodes = requiredNodes.filter(key => {
      return (nodelist[key] === null || nodelist[key].length === 0) ? false : true;
    });
    if (availableNodes.length && availableNodes.length === requiredNodes.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function toArray(obj) {
  return Array.prototype.slice.call(obj);
}

export function request(data, cb) {
  var args = [];
  Object.keys(data).forEach(function(key) {
    args.push(encodeURIComponent(key)+'='+encodeURIComponent(data[key]));
  });
  var xhr = new XMLHttpRequest();
  xhr.open('POST', window.ajaxurl, true);
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.timeout = 10000;

  xhr.onload = function() {
    var data = null;
    try {
      data = JSON.parse(xhr.responseText);
    } catch(e) {} // eslint-disable-line no-empty
    if(data) {
      cb(null, data);
    } else {
      cb('JSON Parsing error');
    }
  };

  xhr.ontimeout = function() {
    cb('Timeout');
  };

  xhr.onerror = function() {
    cb('Error');
  };

  xhr.send(args.join('&'));
}

// http://stackoverflow.com/a/12026134
export function fromLatLngToPixel(map, position) {
  var scale = Math.pow(2, map.getZoom());
  var proj = map.getProjection();
  var bounds = map.getBounds();

  var nw = proj.fromLatLngToPoint(
    new window.google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng()
    ));
  var point = proj.fromLatLngToPoint(position);

  return new window.google.maps.Point(
    Math.floor((point.x - nw.x) * scale),
    Math.floor((point.y - nw.y) * scale));
}

// http://stackoverflow.com/a/12026134
export function fromPixelToLatLng(map, pixel) {
  var scale = Math.pow(2, map.getZoom());
  var proj = map.getProjection();
  var bounds = map.getBounds();

  var nw = proj.fromLatLngToPoint(
    new window.google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng()
    ));
  var point = new window.google.maps.Point();

  point.x = pixel.x / scale + nw.x;
  point.y = pixel.y / scale + nw.y;

  return proj.fromPointToLatLng(point);
}
