import { toArray } from './utils.js';

const TRIGGER_SELECTOR = '.js-toggle-class';

class ClassToggler {
  constructor() {
    const triggers = document.querySelectorAll(TRIGGER_SELECTOR);

    toArray(triggers).forEach(trigger => {
      trigger.addEventListener('click', () => {
        const classname = trigger.getAttribute('data-class');
        const target = trigger.getAttribute('data-target');
        this.toggleClass(classname, target ? target : trigger);
      });
    });
  }

  toggleClass(classname, target) {
    if (typeof target === 'string') {
      const targets = document.querySelectorAll(target);
      toArray(targets).forEach((target) => {
        target.classList.toggle(classname);
      });
    } else {
      target.classList.toggle(classname);
    }
  }
}

export default ClassToggler;
