import { toArray, fromLatLngToPixel, fromPixelToLatLng } from './utils';
import InfoBubble from 'js-info-bubble';
let google = null;

const FULL_WIDTH = 100;

const PAGE_BACKGROUND = '#f1ede9';
const INFOBUBBLE_BACKGROUND = '#343235';

const MARKET_WIDTH = 28;
const MARKET_HEIGHT = 46;
const MARKET_LOGO_WIDTH = 56;
const MARKET_LOGO_HEIGHT = 92;

const PADDING_TOP = 100;
const PADDING_LEFT = 470;
const PADDING_LEFT_XLARGE = 740;

class MapNeighbourhood {
  constructor() {
    this.mapContainer = document.querySelector('.js-neighbourhood-map');
    this.selector = document.querySelector('.js-neighbourhood-map-selector');
    this.tabs = toArray(document.querySelectorAll('.js-neighbourhood-map-tab'));
    this.tabsZooms = this.tabs.map((tab) => tab.dataset.zoom ? parseInt(tab.dataset.zoom) : 0);
    this.tabsLinks = toArray(document.querySelectorAll('.js-neighbourhood-map-tab-link'));
    this.tabsContentContainer = document.querySelector('.js-neighbourhood-map-tabs-content');
    this.tabsContent = toArray(document.querySelectorAll('.js-neighbourhood-map-tab-content'));
    this.tabsContentSections = this.tabsContent.map((tab) => toArray(tab.querySelectorAll('.js-neighbourhood-map-section')));
    this.tabsContentPosts = this.tabsContent.map((tab) => toArray(tab.querySelectorAll('.js-neighbourhood-map-post')));
    this.tabsFirstSections = toArray(document.querySelectorAll('.js-neighbourhood-map-tab-content > .js-neighbourhood-map-section:first-child'))
    this.posts = toArray(document.querySelectorAll('.js-neighbourhood-map-post'));
    this.postsLinks = toArray(document.querySelectorAll('.js-neighbourhood-map-post-link'));
    this.postsBubbles = toArray(document.querySelectorAll('.js-neighbourhood-map-post-bubble'))
    this.sections = toArray(document.querySelectorAll('.js-neighbourhood-map-section'));
    this.sectionsButtons = toArray(document.querySelectorAll('.js-neighbourhood-map-section-btn'));
    this.sectionsContents = toArray(document.querySelectorAll('.js-neighbourhood-map-section-content'));
    this.sectionsZooms = this.sections.map((sec) => sec.dataset.zoom ? parseInt(sec.dataset.zoom) : 0);
    this.mapVisible = window.matchMedia("(min-width: 1024px)");
    this.isXlarge = window.matchMedia("(min-width: 1280px)");
    this.markers = {};
    this.visibleMarkers = [];
    this.activeTab = null;
    this.activeSection = null;

    if(
      this.mapContainer
      &&
      this.selector
      &&
      (this.tabs.length == this.tabsLinks.length &&  this.tabs.length == this.tabsContent.length)
      &&
      (this.posts.length == this.postsLinks.length && this.posts.length  == this.postsBubbles.length)
      &&
      (this.sections.length == this.sectionsButtons.length && this.sections.length == this.sectionsContents.length)
    ) {
      window.onMapsLoad(() => {
        google = window.google;
        this.setupWhenNeeded();
      });
    }
  }

  setupWhenNeeded() {
    if(this.mapVisible.matches) {
      this.setupEverything();
    } else {
      const mediaListener = () => {
        this.setupEverything();
        this.mapVisible.removeListener(mediaListener);
      }
      this.mapVisible.addListener(mediaListener);
    }

  }

  setupEverything() {
    this.setupMap();
    this.setupTabs();
    this.setupPosts();
    this.setupSectionsButtons();

    this.activateTab(0, true);
  }

  setupMap() {
    const map = this.map = window.map = new google.maps.Map(this.mapContainer, {
      center: window.hotelLocation,
      scrollwheel: false,
      zoom: 16,
      styles: window.mapsStyling,
      backgroundColor: PAGE_BACKGROUND,
      mapTypeControlOptions: {position: google.maps.ControlPosition.LEFT_BOTTOM}
    });

    google.maps.event.addListenerOnce(map, 'projection_changed', () => {
      map.setCenter(this.realCenter(window.hotelLocation));
    });

    const marker = new google.maps.Marker({
      map,
      position: window.hotelLocation,
      icon: {
        url: `${window.templateUrl}/dist/assets/markers/contact.svg`,
        scaledSize: new google.maps.Size(MARKET_LOGO_WIDTH, MARKET_LOGO_HEIGHT)
      },
      optimized: false
    });
    marker.addListener('click', () => window.open(window.hotelUrl));

    const infoBubble = new InfoBubble({
      map: map,
      shadowStyle: 0,
      padding: 0,
      backgroundColor: INFOBUBBLE_BACKGROUND,
      borderRadius: 4,
      arrowSize: 10,
      borderWidth: 1,
      borderColor: INFOBUBBLE_BACKGROUND,
      hideCloseButton: true,
      disableAutoPan: true,
      arrowPosition: 50,
      minWidth: 240,
      maxWidth: 240,
      minHeight: 90,
      maxHeight: 90,
      backgroundClassName: 'c-neighbourhood-bubble',
      arrowStyle: 0
    });

    this.infoBubble = infoBubble;

  }

  setupTabs() {
    this.tabsLinks.forEach((link, index) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        this.activateTab(index);
      });
    })
  }

  setupPosts() {
    this.posts.forEach((post, index) => {
      const ID = post.dataset.id,
        lat = post.dataset.lat,
        lng = post.dataset.lng;

      if(!ID || !lat || !lng) {
        return;
      }

      const position = new google.maps.LatLng({lat: parseFloat(lat), lng: parseFloat(lng)});
      const marker = new google.maps.Marker({
        map: this.map,
        position,
        icon: {
          url: `${window.templateUrl}/dist/assets/markers/location.svg`,
          scaledSize: new google.maps.Size(MARKET_WIDTH, MARKET_HEIGHT)
        },
        optimized: false,
        visible: false
      });
      this.markers[ID] = marker;

      const infoBubble = this.infoBubble;
      google.maps.event.addListener(marker, 'click', () => {
        if (infoBubble.isOpen()) {
          infoBubble.close()
        }
        infoBubble.setPosition(position);
        infoBubble.setContent(this.postsBubbles[index].innerHTML);
        infoBubble.open(this.map);
      });

      this.postsLinks[index].addEventListener('click', (e) => {
        e.preventDefault();
        google.maps.event.trigger(marker, 'click');
        this.map.panTo(this.realCenter(position));
      })
    })
  }

  setupSectionsButtons() {
    this.sectionsButtons.forEach((btn, index) => {
      btn.addEventListener('click', () => this.activateSection(index));
    })
  }

  activateTab(index, initial) {
    // TODO acessability

    this.closeActiveSection();

    if(this.activeTab !== null) {
      this.tabs[this.activeTab].classList.remove('is-active');
      this.tabsContent[this.activeTab].classList.remove('is-active');
    }
    this.tabsContentContainer.style.transform = `translateX(-${index*FULL_WIDTH}%)`;
    this.tabs[index].classList.add('is-active');
    this.tabsContent[index].classList.add('is-active');
    this.activeTab = index;

    //this.activateSection(this.sections.indexOf(this.tabsFirstSections[index]));
    this.hideAllMarkers();
    this.showAllMarkersForTab(index);

    if(!initial) {
      if(this.tabsZooms[index]) {
        this.map.setZoom(this.tabsZooms[index]);
      }
      this.map.panTo(this.realCenter(window.hotelLocation));
    }
  }

  activateSection(index) {
    // TODO acessability
    if(this.activeSection == null) {
      this.hideAllMarkers();
    }
    this.closeActiveSection();

    this.selector.classList.toggle('is-animating');
    const transitionEnd = () => {
      this.selector.classList.toggle('is-animating');
      this.sections[index].style.height = `${this.sectionsContents[index].scrollHeight + 1}px`
      this.sections[index].removeEventListener('transitionend', transitionEnd, false);
    }
    this.sections[index].addEventListener('transitionend', transitionEnd, false);
    this.sections[index].classList.add('is-active');
    this.sections[index].style.height = `${this.sectionsContents[index].scrollHeight + 1}px`
    this.sections[index].style.maxHeight = `calc(95vh - 180px - 160px - ${(this.tabsContentSections[this.activeTab].length-1) * 100}px)`;

    const posts = toArray(this.sectionsContents[index].children);
    posts.forEach((post) => this.markers[post.dataset.id] && this.markers[post.dataset.id].setVisible(true));

    if(this.sectionsZooms[index]) {
      this.map.setZoom(this.sectionsZooms[index]);
    } else if(this.tabsZooms[this.activeTab]) {
      this.map.setZoom(this.tabsZooms[this.activeTab]);
    }
    this.map.panTo(this.realCenter(window.hotelLocation));

    this.activeSection = index;
  }

  hideAllMarkers() {
    Object.keys(this.markers).forEach((markerId) => this.markers[markerId].setVisible(false));
    this.closeinfoBubble();
  }

  showAllMarkersForTab(index) {
    const posts = this.tabsContentPosts[index];
    posts.forEach((post) => this.markers[post.dataset.id] && this.markers[post.dataset.id].setVisible(true));
  }

  closeActiveSection() {
    if(this.activeSection !== null) {
      this.sections[this.activeSection].classList.remove('is-active');
      this.sections[this.activeSection].style.height = '';

      const posts = toArray(this.sectionsContents[this.activeSection].children);
      posts.forEach((post) => this.markers[post.dataset.id] && this.markers[post.dataset.id].setVisible(false));

      this.closeinfoBubble();
      this.activeSection = null;
    }
  }

  closeinfoBubble() {
    if (this.infoBubble.isOpen()) {
      this.infoBubble.close()
    }
  }

  realCenter(position) {
    if(!(position instanceof google.maps.LatLng)) {
      position = new google.maps.LatLng(position);
    }
    const positionPoint = fromLatLngToPixel(this.map, position);
    positionPoint.x -= (this.isXlarge.matches ? PADDING_LEFT_XLARGE/2 : PADDING_LEFT/2);
    positionPoint.y -= PADDING_TOP/2;
    return fromPixelToLatLng(this.map, positionPoint);
  }
}

export default MapNeighbourhood;
