import { toArray } from './utils';
import Cookies from 'js-cookie';

const ACTIVE_CLASS   = 'alert-open';
const EXPAND_CLASS   = 'alert-expanded';
const COOKIE_NAME = 'alertBarCookie';
const alert = document.querySelector('.alert-wrapper');
const pushedElements = document.querySelectorAll('.alert-open');
const alertElement = document.querySelector('.alert-bar');
const closeButton = document.querySelector('.js-alert-close');
const expandedPanel = document.querySelector('.alert-expanded-panel');
const closeExpanded = document.querySelector('.js-expand-close');

class AlertBar {
  constructor() {
    if(!Cookies.get(COOKIE_NAME)) {
      if (alert) { // check if alert is there
        this.showAlertBar();
        this.alertPushElements(); // Set intial alert height and pushed element top
        this.resizePushElements(); // Adjust pushed element top on resize
        this.bindExpandEvent(); // Expandable function event listener
        this.bindCloseEvent(); // Close Alert Message
      }
    }
  }

  showAlertBar() {
    alertElement.classList.add(ACTIVE_CLASS);
  }

  expandAlertBar() {
    alertElement.classList.add(EXPAND_CLASS);
    expandedPanel.setAttribute('aria-hidden', 'false');  
    alert.setAttribute('aria-expanded', 'true');  
  }

  removeAlertBar() {
    alertElement.classList.remove(ACTIVE_CLASS);
  }

  alertPushElements() {
    const height = alert.offsetHeight;
    toArray(pushedElements).forEach(trigger => {
      trigger.style.marginTop = height + 'px';
      if(trigger.classList.contains('c-nav-mobile')) {
        trigger.style.height = 'calc(100vh - ' + height + 'px';
      }
    });
  }

  resizePushElements() {
    window.addEventListener('resize', () => {
      this.alertPushElements();
    });
  }

  bindCloseEvent() {
    closeButton.addEventListener('click', () => {
      Cookies.set(COOKIE_NAME, true);
      this.removeAlertBar();

      toArray(pushedElements).forEach(trigger => {
        trigger.style.marginTop = 0;
        if(trigger.classList.contains('c-nav-mobile')) {
          trigger.style.height = '100vh';
        }
        trigger.classList.remove(ACTIVE_CLASS);
      });
      
    });
  }

  // Functions for Expanded Panel in notification bar
  bindExpandEvent() {
    if (expandedPanel) {
      alert.addEventListener('click', () => {
        this.expandAlertBar();
        this.bindCloseExpanded();
      });
    }
  }

  closeExpandedPanel() {
    if( alertElement.classList.contains(EXPAND_CLASS) ) {
      alertElement.classList.remove(EXPAND_CLASS);
      expandedPanel.setAttribute('aria-hidden', 'true');
      alert.setAttribute('aria-expanded', 'false');
    }
  }

  bindCloseExpanded() {
    closeExpanded.addEventListener('click', () => {
      this.closeExpandedPanel();
    });
    closeExpanded.addEventListener('touchstart', () => {
      this.closeExpandedPanel();
    });
  }
}

export default AlertBar;
