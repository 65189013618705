import { toArray } from './utils';

class NeighbourhoodMobile {
  constructor() {
    this.sections = toArray(document.querySelectorAll('.js-neighbourhood-mobile-section'));
    this.sectionsButtons = toArray(document.querySelectorAll('.js-neighbourhood-mobile-section-btn'));
    this.posts = toArray(document.querySelectorAll('.js-neighbourhood-mobile-post'));
    this.postsButtons = toArray(document.querySelectorAll('.js-neighbourhood-mobile-post-btn'));

    if(!(
      (this.sections.length && this.posts.length)
      &&
      (this.sections.length == this.sectionsButtons.length)
      &&
      (this.posts.length == this.postsButtons.length)
    )) {
      return;
    }

    this.setupListeners();
  }

  setupListeners() {
    this.sectionsButtons.forEach((btn, index) => {
      btn.addEventListener('click', () => this.toggleSection(index));
    })

    this.postsButtons.forEach((btn, index) => {
      btn.addEventListener('click', () => this.togglePost(index));
    })
  }

  toggleSection(index) {
    this.sections[index].classList.toggle('is-open');
  }

  togglePost(index) {
    this.posts[index].classList.toggle('is-open');
  }
}

export default NeighbourhoodMobile;
