import { areNodesPresent, toArray } from './utils.js';
import Handlebars from 'handlebars';
import customSelect from './vendor/custom-select.modified.js';

const MAIN_SELECTOR         = '.js-gallery-carousel';
const FRAME_SELECTOR        = '.js-gallery-frame';
const CONTAINER_SELECTOR    = '.js-gallery-container';
const ELEMENT_SELECTOR      = '.js-gallery-element';
const PREV_SELECTOR         = '.js-gallery-prev';
const NEXT_SELECTOR         = '.js-gallery-next';
const SELECT_SELECTOR       = '.js-gallery-select';
const CAPTION_SELECTOR      = '.js-gallery-caption';
const RESIZABLE_SELECTOR    = '.js-gallery-resizable';
const THUMBS_OUTER_SELECTOR = '.js-gallery-thumbs-outer';
const THUMBS_INNER_SELECTOR = '.js-gallery-thumbs-inner';
const THUMBS_SELECTOR       = '.js-gallery-thumbs';
const THUMB_SELECTOR        = '.js-gallery-thumb';

const ACTIVE_CLASSNAME      = 'is-active';

const SCROLL_TIME = 0.2;
const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;
const REQUIRED_DRAG_PERCENTAGE = 10;
const PERCENTS = 100;
const DEBOUNCE_INTERVAL = 200;

class GalleryCarousel {
  constructor() {
    const carousel = document.querySelector(MAIN_SELECTOR);
    if (carousel) {
      const nodes = this.getNodes(carousel);
      const gallery = window.gallery;
      if (areNodesPresent(nodes) && gallery && gallery.data && gallery.templates) {
        this.DOM = nodes;
        this.data = gallery.data;
        this.templates = gallery.templates;
        this.bindSelectEvents();
        this.buildGallery(0);
        this.normalizeThumbContainerSize();
        this.bindThumbEvents();
        this.bindNavEvents();
        this.bindTouchEvents();
        this.bindKeyboardEvents();
        this.bindWindowTriggeredResizeEvents();
        this.bindUserTriggeredResizeEvents();
        this.slideTo(0);
      }
    }
  }

  getNodes(carousel) {
    return {
      carousel,
      frame:       carousel.querySelector(FRAME_SELECTOR),
      container:   carousel.querySelector(CONTAINER_SELECTOR),
      elements:    carousel.querySelectorAll(ELEMENT_SELECTOR),
      thumbsOuter: carousel.querySelector(THUMBS_OUTER_SELECTOR),
      thumbsInner: carousel.querySelector(THUMBS_INNER_SELECTOR),
      thumbs:      carousel.querySelector(THUMBS_SELECTOR),
      prev:        document.querySelector(PREV_SELECTOR),
      next:        document.querySelector(NEXT_SELECTOR),
      caption:     document.querySelector(CAPTION_SELECTOR),
    };
  }

  bindSelectEvents() {
    const selects = document.querySelectorAll(SELECT_SELECTOR);
    toArray(selects).forEach(select => {
      const instance = customSelect(select)[0];
      instance.select.addEventListener('change', (ev) => {
        this.changeGallery(ev.target.value);
        this.dispatchResizeWindowEvent();
      });
    });
  }

  dispatchResizeWindowEvent() {
    const ev = window.document.createEvent('UIEvents');
    ev.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(ev);
  }

  changeGallery(n) {
    this.clearGallery();
    this.buildGallery(n);
    this.slideTo(0);
  }

  clearGallery() {
    this.clearContainer();
    this.clearThumbs();
  }

  clearThumbs() {
    this.getThumbGroups().forEach(group => {
      group.innerHTML = '';
    });
  }

  clearContainer() {
    this.getContainer().innerHTML = '';
  }

  buildGallery(n) {
    this.populateThumbnails(this.data[n].images);
    this.setActiveThumb(0);
    this.clearContainer();
    this.populateContainer(this.data[n].images);
    this.updateElements();
    this.total = this.data[n].images.length;
  }

  getContainer() {
    return document.querySelector(CONTAINER_SELECTOR);
  }

  getThumbGroups() {
    const thumbGrups = document.querySelectorAll(THUMBS_SELECTOR);
    return toArray(thumbGrups);
  }

  populateContainer(images) {
    const tpl = this.templates.element;
    const template = Handlebars.compile(tpl);
    const nodes = images.map((img, i) => {
      const htmlstring = template({img, i});
      return this.createNode(htmlstring);
    });
    nodes.forEach(node => this.getContainer().appendChild(node));
  }

  updateElements() {
    if (this.DOM) {
      this.DOM.elements = this.DOM.carousel.querySelectorAll(ELEMENT_SELECTOR);
    }
  }

  populateThumbnails(images) {
    const tpl   = this.templates.thumbnail;
    const template = Handlebars.compile(tpl);
    const nodes = images.map((img, i) => {
      const htmlstring = template({img, i});
      return this.createNode(htmlstring);
    });
    nodes.forEach(node => {
      this.DOM.thumbs.appendChild(node);
    });
  }

  normalizeThumbContainerSize() {
    const thumbSize = this.DOM.thumbs.firstChild.offsetWidth;
    const outerSize = this.DOM.thumbsOuter.offsetWidth;
    const normalizedSize = outerSize - (outerSize % thumbSize);
    const finalSize = Math.min(thumbSize * this.total, normalizedSize);
    this.thumbsInView = normalizedSize / thumbSize;
    this.DOM.thumbsInner.style.width = finalSize+'px';
  }

  createNode(string) {
    var div = document.createElement('div');
    div.innerHTML = string;
    return div.children[0];
  }

  slideTo(n) {
    if (n < 0 || n > this.total-1) {
      return;
    }

    const caption = this.DOM.elements[n].getAttribute('data-caption');
    this.translateContainerTo(-n*PERCENTS);
    this.setCaption(caption);
    this.setActiveThumb(n);
    this.shiftThumbs(n);
    this.updateNavState();
  }

  updateNavState() {
    if (this.current === 0) {
      this.DOM.prev.classList.add('disabled');
    } else {
      this.DOM.prev.classList.remove('disabled');
    }

    if (this.current === this.total-1) {
      this.DOM.next.classList.add('disabled');
    } else {
      this.DOM.next.classList.remove('disabled');
    }
  }

  getThumbWidth() {
    return this.DOM.thumbs.children[0].offsetWidth;
  }

  shiftThumbs(n, force) {
    const currentShift = this.currentShift || 0;
    const inView = this.thumbsInView - 1;
    let shift;

    if (n > inView + currentShift) {
      shift = n - inView;
    } else if (n < currentShift) {
      shift = n;
    } else if (force) {
      shift = 0;
    }

    if (shift !== undefined) {
      this.translateThumbsTo(-1 * shift * this.getThumbWidth());
      this.currentShift = shift;
    }
  }

  translateThumbsTo(value) {
    this.DOM.thumbs.style.transform = 'translateX('+value+'px)';
  }

  translateContainerTo(value) {
    this.DOM.container.style.transform = 'translateX('+value+'%)';
  }

  setCaption(text) {
    this.DOM.caption.innerText = text;
  }

  bindKeyboardEvents() {
    document.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KEYCODE_LEFT) {
        this.slideTo(this.current - 1);
      }
      if (ev.keyCode === KEYCODE_RIGHT) {
        this.slideTo(this.current + 1);
      }
    });
  }

  bindThumbEvents() {
    document.addEventListener('click', (ev) => { // `delegate` syntax
      for (let target=ev.target; target && target!=this; target=target.parentNode) {
        if (!target.matches && target.msMatchesSelector) {
          target.matches = target.msMatchesSelector;
        }
        if (target.matches && target.matches(THUMB_SELECTOR)) {
          const index = Number(target.getAttribute('data-index'));
          this.slideTo(index);
          break;
        }
      }
    });
  }

  bindTouchEvents() {
    const touchMove = this.touchMove.bind(this);
    this.DOM.frame.addEventListener('touchstart', (ev) => {
      ev.preventDefault();
      this.touchPosition = ev.changedTouches[0].screenX;
      this.DOM.carousel.classList.add('is-dragging');
      this.DOM.container.addEventListener('touchmove', touchMove);
    });
    this.DOM.frame.addEventListener('touchend', (ev) => {
      ev.preventDefault();
      this.DOM.carousel.classList.remove('is-dragging');
      this.DOM.container.removeEventListener('touchmove', touchMove);
      this.snap();
    });
  }

  touchMove(ev) {
    ev.preventDefault();
    const carouselWidth = this.DOM.carousel.offsetWidth;
    const delta = ev.changedTouches[0].screenX - this.touchPosition;
    const percentage = delta / carouselWidth * PERCENTS;
    this.drag(percentage);
  }

  drag(percentage) {
    const newPosition = (this.current * PERCENTS * -1) + percentage;
    this.currentDragPercentage = percentage;
    this.translateContainerTo(newPosition);
  }

  snap() {
    const total = this.DOM.elements.length;
    let snapIndex = this.current;
    if (this.currentDragPercentage < REQUIRED_DRAG_PERCENTAGE * -1) {
      snapIndex++;
    } else if (this.currentDragPercentage > REQUIRED_DRAG_PERCENTAGE) {
      snapIndex--;
    }
    this.slideTo(Math.min(Math.max(0, snapIndex), total-1));
    this.currentDragPercentage = 0;
  }

  scrollToTop() {
    window.TweenLite.to(window, SCROLL_TIME, {
      scrollTo: 0,
    });
  }

  setActiveThumb(n) {
    this.getThumbGroups().forEach(group => {
      const thumbs = group.querySelectorAll(THUMB_SELECTOR);
      toArray(thumbs).forEach(thumb => {
        thumb.classList.remove(ACTIVE_CLASSNAME);
      });
      thumbs[n].classList.add(ACTIVE_CLASSNAME);
    });
    this.current = n;
  }

  bindNavEvents() {
    this.DOM.prev.addEventListener('click', () => {
      const prev = Math.max(0, this.current - 1);
      this.slideTo(prev);
    });
    this.DOM.next.addEventListener('click', () => {
      const next = Math.min(this.total-1, this.current + 1);
      this.slideTo(next);
    });
  }

  bindWindowTriggeredResizeEvents() {
    let tmoHandler;
    window.addEventListener('resize', () => {
      clearTimeout(tmoHandler);
      tmoHandler = setTimeout(() => {
        this.normalizeThumbContainerSize();
      }, DEBOUNCE_INTERVAL);
    });
  }

  bindUserTriggeredResizeEvents() {
    const resizableElements = document.querySelectorAll(RESIZABLE_SELECTOR);
    toArray(resizableElements).forEach(el => {
      el.addEventListener('transitionend', (ev) => {
        if (ev.propertyName === 'left') {
          this.normalizeThumbContainerSize();
          this.shiftThumbs(this.current, true);
        }
      });
    });
  }
}

export default GalleryCarousel;
