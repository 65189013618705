const STICKY_CLASSNAME = 'has-sticky-header';

class StickyMenu {
  constructor() {
    this.DOM = {
      body: document.getElementsByTagName('body')[0]
    };
    this.bindLoadEvent();
    this.bindScrollEvent();
    this.dispatchResizeWindowEvent();
  }

  dispatchResizeWindowEvent() {
    const ev = window.document.createEvent('UIEvents');
    ev.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(ev);
  }

  bindLoadEvent() {
    window.addEventListener('load', () => {
      const doc = document.documentElement;
      const body = document.body;
      const currentScroll = doc.scrollTop || body.scrollTop || 0;
      this.stickHeader(Boolean(Math.max(0, currentScroll)));
    });
  }

  bindScrollEvent() {
    window.addEventListener('scroll', () => {
      const doc = document.documentElement;
      const body = document.body;
      const currentScroll = doc.scrollTop || body.scrollTop || 0;
      this.stickHeader(Boolean(Math.max(0, currentScroll)));
    });
  }

  stickHeader(flag) {
    if (flag) {
      this.DOM.body.classList.add(STICKY_CLASSNAME);
    } else {
      this.DOM.body.classList.remove(STICKY_CLASSNAME);
    }
  }
}

export default StickyMenu;
