import { toArray, areNodesPresent, request } from './utils.js';

const FORM_SELECTOR   = '#quick-subscription';
const INPUT_SELECTOR  = '#email';
const SUBMIT_SELECTOR = '.js-subscribe';
const OPEN_CLASS      = 'is-open';
const SUCCESS_CLASS   = 'is-success';
const ERROR_CLASS     = 'is-error';

class Newsletter {
  constructor() {
    const nodes = this.getNodes();
    if (areNodesPresent(nodes)) {
      this.DOM = nodes;
      this.bindEmailKeyupListener();
      this.bindSubmitButtons();
      this.catchSubmission();
    }
  }

  getNodes() {
    return {
      form: document.querySelector(FORM_SELECTOR),
      input: document.querySelector(INPUT_SELECTOR),
      buttons: document.querySelectorAll(SUBMIT_SELECTOR)
    };
  }

  bindEmailKeyupListener() {
    const input = document.querySelector(INPUT_SELECTOR);
    input.addEventListener('input', () => {
      this.validateEmail.call(this);
    });
  }

  bindSubmitButtons() {
    toArray(this.DOM.buttons).forEach(button => {
      button.addEventListener('click', () => {
        this.submit();
      });
    });
  }

  catchSubmission() {
    this.DOM.form.addEventListener('submit', (ev) => {
      ev.preventDefault();
      this.submit();
    });
  }

  validateEmail() {
    const isValid = this.DOM.input.checkValidity();
    if (isValid) {
      this.DOM.form.classList.add('is-valid');
      this.DOM.form.classList.remove('is-invalid');
    } else {
      this.DOM.form.classList.add('is-invalid');
      this.DOM.form.classList.remove('is-valid');
    }
    return isValid;
  }

  submit() {
    if (this.validateEmail()) {
      request({
        action: 'sub_mailchimp',
        email: this.DOM.input.value
      }, (error) => {
        if (error) {
          this.onSubmitError();
        } else {
          this.onSubmitSuccess();
        }
      });
    }
  }

  onSubmitSuccess() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'SubmitNewsletterForm'
    });
    this.DOM.form.classList.add(SUCCESS_CLASS);
    this.DOM.form.classList.remove(OPEN_CLASS);
  }

  onSubmitError() {
    this.DOM.form.classList.add(ERROR_CLASS);
    this.DOM.form.classList.remove(OPEN_CLASS);
  }
}

export default Newsletter;
