import { toArray, request } from './utils';
const ACTION = 'get_press';
const TEMPLATE = {
  START: `
  <div class="left-aligned-grid__row">
    <div class="o-container--narrow">
      <div class="left-aligned-grid__row-container">
        <div class="left-aligned-grid__column js-parallax">
  `,
  FIRST_END: `</div>`,
  SECOND_START: `<div class="left-aligned-grid__column js-parallax">`,
  SECOND_END: `</div>`,
  END: `</div></div></div>`
}

class PressLoader {
  constructor(config) {
    this.container = document.querySelector('.js-press-container');
    this.nearEnd = document.querySelector('.js-press-near-end');
    this.category = document.querySelector('.js-press-category');

    if(!this.container || !this.nearEnd) {
      return;
    }

    this.parallax = config.parallax;
    this.loading = false;
    this.currentPage = 1;
    this.hasMore = true;
    this.observer = null;
    this.setupListeners();
  }

  setupListeners() {
    const observerOptions = {};
    this.observer =
      new window.IntersectionObserver(this.observerCallback.bind(this), observerOptions);
    this.observer.observe(this.nearEnd);

    if(this.category) {
      this.category.addEventListener('change', () => this.reloadResults());
    }
  }

  observerCallback(entries) {
    entries.forEach((entry) => {
      //console.log(!!entry.intersectionRect.top , entry);
      if(entry.intersectionRect.top > 0) {
        this.load();
      }
    })
  }

  reloadResults() {
    this.currentPage = 0;
    this.hasMore = true;
    this.load();
  }

  load() {
    if(this.loading || !this.hasMore) {
      return;
    }
    this.loading = true;

    request({
      action: ACTION,
      page: this.currentPage + 1,
      category: ((this.category && this.category.value) || '')
    }, (err, data) => {
      this.loading = false;
      if(err) {
        return;
      }
      if(this.currentPage == 0) {
        this.container.innerHTML = '';
      }
      this.currentPage++;
      let posts = data.posts;
      for(let i = 0; i < posts.length; i = i+2) {
        const post1 = posts[i];
        const post2 = posts[i+1];
        let str = TEMPLATE.START;
        str += post1
        str += TEMPLATE.FIRST_END;
        if(post2) {
          str += TEMPLATE.SECOND_START + post2 + TEMPLATE.SECOND_END;
        }
        str += TEMPLATE.END;

        let tmp = document.createElement('div');
        tmp.innerHTML = str;
        const el = tmp.children[0];
        this.container.appendChild(el);
        this.parallax.add(toArray(el.querySelectorAll('.js-parallax')));

        if(!data.has_more) {
          this.hasMore = false;
        }
      }
    })

  }

}

export default PressLoader;
