import { toArray } from './utils';
import '../../node_modules/waypoints/lib/noframework.waypoints.js';
/* global Waypoint:true */

const DARK_SELECTOR = '.js-tone-dark';
const DARK_CLASSNAME = 'is-over-dark';

class ColorDetection {
  constructor() {
    this.DOM = {
      body: document.getElementsByTagName('body')[0]
    };

    const sections = document.querySelectorAll(DARK_SELECTOR);

    toArray(sections).forEach(section => {
      new Waypoint({
        element: section,
        offset: this.offsetForTopEdge,
        handler: this.handlerForTopEdge.bind(this)
      });
      new Waypoint({
        element: section,
        offset: this.offsetForBottomEdge,
        handler: this.handlerForBottomEdge.bind(this)
      });
    });
  }

  offsetForTopEdge() {
    const viewportHeight = window.innerHeight;
    return viewportHeight / 2;
  }

  offsetForBottomEdge() {
    const viewportHeight = window.innerHeight;
    const elementHeight = this.element.offsetHeight;
    return (viewportHeight / 2) - elementHeight;
  }

  handlerForTopEdge(direction) {
    if (direction === 'down') {
      this.intoDark();
    } else {
      this.outOfDark();
    }
  }

  handlerForBottomEdge(direction) {
    if (direction === 'down') {
      this.outOfDark();
    } else {
      this.intoDark();
    }
  }

  intoDark() {
    this.DOM.body.classList.add(DARK_CLASSNAME);
  }

  outOfDark () {
    this.DOM.body.classList.remove(DARK_CLASSNAME);
  }
}

export default ColorDetection;
