/*
  Project: William Gray
  Author: Xfive
 */

import 'svgxuse';
import './vendor/modernizr.custom.min.js';
import 'intersection-observer';

import AlertBar from './AlertBar';
import ClassToggler from './ClassToggler';
import ClickRedirect from './ClickRedirect';
import ObjectFitFallback from './ObjectFitFallback';
import ColorDetection from './ColorDetection';
import StickyMenu from './StickyMenu';
import MapNeighbourhood from './MapNeighbourhood';
import Newsletter from './Newsletter';
import QuickSubscription from './QuickSubscription';
import SlicegridCarousel from './SlicegridCarousel';
import Carousel from './Carousel';
import VenuesLightboxes from './VenuesLightboxes';
import NeighbourhoodMobile from './NeighbourhoodMobile';
import GalleryCarousel from './GalleryCarousel';
import GalleryState from './GalleryState';
import Parallax from './Parallax';
import PressLoader from './PressLoader';
import MapHome from './MapHome';
import Playback from './Playback';
import FormOverlay from './FormOverlay';
import ScrollManager from './ScrollManager';
import SmoothScroll from './SmoothScroll';

new AlertBar();
new ClassToggler();
new ClickRedirect();
new ObjectFitFallback();
new ColorDetection();
new StickyMenu();
new MapNeighbourhood();
new Newsletter();
new QuickSubscription();
new SlicegridCarousel();
new Carousel();
new VenuesLightboxes();
new NeighbourhoodMobile();
new GalleryCarousel();
new GalleryState();
const parallax = new Parallax();
new PressLoader({parallax});
new MapHome();
new Playback();
new FormOverlay();
new ScrollManager();
new SmoothScroll();
