import smoothScroll from './vendor/smooth-scroll.js';
import { toArray } from './utils.js';

class SmoothScroll {
  constructor() {
    this.trigger = toArray(document.querySelectorAll('.js-smooth-scroll-btn'))

    this.setup();
  }

  setup() {
    this.trigger.forEach((el) => {
      el.addEventListener('click', () =>
        smoothScroll.animateScroll(
          document.querySelector(el.dataset.scrollTarget),
          null,
          {offset: 110}
        )
      )
    })
  }
}

export default SmoothScroll;
