import { toArray } from './utils.js';

const PLAYBACK_STOP_SELECTOR = '.js-playback-stop';

class Playback {
  constructor() {
    const stopButtons = document.querySelectorAll(PLAYBACK_STOP_SELECTOR);
    toArray(stopButtons).forEach(stopButton => {

      stopButton.addEventListener('click', () => {
        const target = stopButton.getAttribute('data-playback-target');
        const element = document.querySelector(target);
        element.setAttribute('src', '');
      });
    });
  }
}

export default Playback;
